(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/modals');

require('./modules/forms');

require('./modules/masks');

require('./modules/spinner');

require('./modules/scroll');

require('./modules/menus');

require('./modules/tabs');

require('./modules/galleries');

require('./modules/rotators');

require('./modules/parallax');

require('./modules/topmenu');

require('./modules/inview');

require('./modules/expandable');

require('./modules/video');

require('./pages/mainpage');

require('./pages/catalogue');

require('./pages/card');

// Disable buttons link
$('.disabled').on('click', function (e) {
  e.preventDefault();
});

// Disable drag links and imgs dy default


// pages
/* global PerfectScrollbar */

// modules
$('img, a').on('dragstart', function (e) {
  e.preventDefault();
});

// Activate custom scrollbar
$('.js-perfect-scrollbar').each(function scrollbarHandler() {
  var ps = new PerfectScrollbar($(this)[0]);
  $(this).data('ps', ps);
});

},{"./modules/expandable":3,"./modules/forms":4,"./modules/galleries":5,"./modules/inview":8,"./modules/masks":9,"./modules/menus":10,"./modules/modals":11,"./modules/parallax":12,"./modules/rotators":13,"./modules/scroll":14,"./modules/spinner":15,"./modules/tabs":16,"./modules/topmenu":17,"./modules/video":18,"./pages/card":19,"./pages/catalogue":20,"./pages/mainpage":21}],2:[function(require,module,exports){
module.exports={
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1200,
    "normal": 1400
  }
}

},{}],3:[function(require,module,exports){
'use strict';

$('.js-expand-all').on('click', function clickHandler(e) {
  e.preventDefault();
  var $component = $(this).closest('.js-expandable-list');

  $component.find('.expandable-list--item').addClass('is-expanded');
  $component.find('.js-expand-all').css('display', 'none');
  $component.find('.js-collapse-all').css('display', 'inline-block');
});

$('.js-collapse-all').on('click', function clickHandler(e) {
  e.preventDefault();
  var $component = $(this).closest('.js-expandable-list');

  $component.find('.expandable-list--item').removeClass('is-expanded');
  $component.find('.js-expand-all').css('display', 'inline-block');
  $component.find('.js-collapse-all').css('display', 'none');
});

$('.js-expandable-list .expandable-list--item-title').on('click', function clickHandler() {
  var $this = $(this);

  if ($this.siblings('.expandable-list--item-description').length) {
    $(this).closest('.expandable-list--item').toggleClass('is-expanded');
  }
});

},{}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');

  if ($inp.prop('disabled')) {
    $inp.closest('label').addClass('is-disabled');
  }
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

autosize($('textarea'));

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

},{"./globals":6}],5:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

$(function () {
  // init tosrus static gallery
  $('.js-gallery').each(function galleryHandler() {
    $(this).find('.js-gallery-item').tosrus(_globals.TOSRUS_DEFAULTS);
  });
});

},{"./globals":6}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfig = require('../media-queries-config.json');

var _mediaQueriesConfig2 = _interopRequireDefault(_mediaQueriesConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

},{"../media-queries-config.json":2}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals":6}],8:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// IN-VIEW - отслеживание попадания объектов в поле видимости
// ----------------------------------------------------------

var $animationElements = $('.js-inview-watcher');

function checkIfInView() {
  var windowHeight = _globals.$WINDOW.height();
  var windowTopPosition = _globals.$WINDOW.scrollTop();
  var windowBottomPosition = windowTopPosition + windowHeight;

  $.each($animationElements, function iterationHandler() {
    var $element = $(this);
    var elementHeight = $element.outerHeight();
    var k = 0.33;
    if (_globals.$WINDOW.height() > _globals.$WINDOW.width()) {
      k = 0.1; // если мобильное разрешение
    }
    var elementTopPosition = $element.offset().top + windowHeight * k; // на 33% попадает в экран
    var elementBottomPosition = elementTopPosition + elementHeight;

    // check to see if this current container is within viewport
    if (elementBottomPosition >= windowTopPosition && elementTopPosition <= windowBottomPosition) {
      $element.addClass('in-view');
    } else {
      // выход из поля зрения не отмечаем
      // $element.removeClass('in-view');
    }
  });
}
_globals.$WINDOW.on('scroll resize', checkIfInView);
_globals.$WINDOW.trigger('scroll');

},{"./globals":6}],9:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

(function () {

  $('input[type="tel"]').mask('9 (999) 999-99-99', {
    autoclear: false
  });

  if (_globals.IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }
})();

},{"./globals":6}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

$(function () {

  var $menus = $('.js-menu');

  if (_globals.IS_DESKTOP) {
    $menus.on('mouseenter.js-menu', 'li', function mouseenterHandler() {
      var $this = $(this);

      clearTimeout($this.data('unhoverTimeout'));

      if ($this.hasClass('has-dropdown')) {
        $this.data('hoverTimeout', setTimeout(function () {
          $this.addClass('is-hovered');
        }, 500));
      } else {
        $this.addClass('is-hovered');
      }
    });

    $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
      var $this = $(this);

      clearTimeout($this.data('hoverTimeout'));

      $this.data('unhoverTimeout', setTimeout(function () {
        $this.removeClass('is-hovered');
      }, 200));
    });
  }

  if (_globals.IS_MOBILE) {
    $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
      e.preventDefault();

      var $anchor = $(this);
      var $parent = $anchor.parent();
      var hasDropdown = $parent.hasClass('has-dropdown');
      var isHovered = $parent.hasClass('is-hovered');
      var link = $anchor.attr('href');

      $parent.siblings().removeClass('is-hovered');

      if (!hasDropdown) {
        document.location.href = $anchor.attr('href');
      } else if (isHovered && link) {
        document.location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    });
  }

  $('.js-toggle-mobile-menu').click(function (e) {
    e.preventDefault();

    var openedClass = 'menu-is-opened';
    _globals.$BODY.toggleClass(openedClass);

    if (_globals.$BODY.hasClass(openedClass)) {
      _globals.$BODY.on('click.close-menu', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.mobile-menu').length || $target.hasClass('js-toggle-mobile-menu')) {

          if ($target.parent().parent().hasClass('menu')) {
            // пропускаем обычную ссылку
          } else {
            ev.stopPropagation();
            return false;
          }
        }

        return _globals.$BODY.removeClass(openedClass).off('click.close-menu');
      });
    } else {
      _globals.$BODY.removeClass(openedClass).off('click.close-menu');
    }
  });

  $('.js-toggle-menu-item').click(function clickHandler(e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).closest('.has-dropdown').toggleClass('is-hovered');
  });

  // $('.js-mobile-search .btn').click(function() {
  //   $(this).closest('form').submit();
  // });
});

},{"./globals":6}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });
});

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

},{"./globals":6}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

_globals.$DOCUMENT.on('scroll', function () {
  var $parallaxHeader = $('.parallax-header');
  if ($parallaxHeader.length > 0) {
    var y = $(document).scrollTop() + 1;
    var y1 = y / 1.5;
    var y2 = y / 5.5;
    var h = $('.parallax-header').height() + 1;
    var t = y / h;
    if (t > 1) {
      t = 1;
    }

    $('.parallax-header').css('background-position-y', y2);
    $('.parallax-header .title').css('transform', 'translateY(' + y1 + 'px)');
    $('.parallax-header .title').css('opacity', 1 - t);
  }
});

},{"./globals":6}],13:[function(require,module,exports){
'use strict';

/* global Swiper */

$('.js-slideshow').each(function sliderFunction() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
});

$('.js-rotator-slider').each(function sliderFunction() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
  $(this).find('.start-slide').removeClass('start-slide');
});

$('.js-gallery-slider').each(function sliderFunction() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [{
      breakpoint: 1199 + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 1024 + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 768 + 1,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 576 + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

(function () {
  return new Swiper('.js-swiper-product-card', {
    direction: 'horizontal',
    simulateTouch: true,
    allowTouchMove: true,
    followFinger: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
})();

(function () {
  return new Swiper('.js-swiper-card-videos .swiper-container', {
    direction: 'horizontal',
    simulateTouch: true,
    allowTouchMove: true,
    followFinger: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });
})();

(function () {
  return new Swiper('.js-swiper-vitrine-videos', {
    direction: 'horizontal',
    simulateTouch: true,
    allowTouchMove: true,
    followFinger: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    //   clickable: true,
    // },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
})();

(function () {
  return new Swiper('.js-infopage-subpages-swiper .swiper-container', {
    direction: 'horizontal',
    simulateTouch: true,
    allowTouchMove: true,
    followFinger: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
})();

},{}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $lnk = $(this);
  var href = $lnk.attr('href');
  var $elemToScroll = $(href);
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || 0;

  if ($(window).width() > 700) {
    offset -= 90;
  } else {
    offset -= 68;
  }

  // console.log( "scrollTo >> href: " + href + " | speed: " + speed + " offset: " + offset);
  _globals.$WINDOW.scrollTo($elemToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $goTopBtn = $('<div class="go-top-btn"></div>');

  $goTopBtn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopBtn.addClass('is-visible');
    } else {
      $goTopBtn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopBtn);
}

// about page
// ----------

var $asidePanel = $('.js--info-page--aside');
if ($asidePanel.length) {

  var $asideTop = $('.info-page--content').offset().top;
  var $panelHeight = $asidePanel.height();
  var $footerTop = $('.footer').offset().top;
  var $topBorder = $asideTop - 40 - 89;
  var $bottomBorder = $footerTop - $panelHeight - 40 - 89;

  _globals.$WINDOW.scroll(function () {
    var $scrollTop = _globals.$WINDOW.scrollTop();

    if (_globals.$WINDOW.width() > 1600) {
      if ($scrollTop > $topBorder) {

        $asidePanel.addClass('is-fixed');
        if ($scrollTop >= $bottomBorder) {
          $asidePanel.fadeOut(250);
        } else {
          $asidePanel.fadeIn(250);
        }
      } else {
        $asidePanel.removeClass('is-fixed');
      }
    }
  });
}

},{"./globals":6,"./helpers":7}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (e.keyCode === 46 || e.keyCode === 8 || e.keyCode === 9 || e.keyCode === 27 || e.keyCode === 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return e.keyCode;
  }
  if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals.js":6}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// default tabs
// ------------
$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();
  var $this = $(this);
  var $panel = $($this.attr('href'));
  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.js-tabs').find('.tabs-panel').hide();
  $panel.fadeIn();
});

// дополнение к .js-scroll-to
// переключает на нужную вкладку

$('.js-scroll-to').on('click', function clickHandler(e) {
  e.preventDefault();
  var $this = $(this);
  var $href = $this.attr('href');
  var $panel = $($href);
  if ($href.length > 5) {
    if ($href.substr(0, 5) === '#tab-') {
      var $tabId = $href.substr(5, $href.length - 5);
      var $tab = $panel.closest('.js-tabs').find('#' + $tabId + ' a');
      $tab.trigger('click');
    }
  }
});

// открывает вкладку по #hash
// (without GET-parameters)

_globals.$DOCUMENT.ready(function () {
  var $hash = window.location.hash;
  var $panel = $($hash);
  if ($hash === '') return;
  if ($hash.length > 5) {
    if ($hash.substr(0, 5) === '#tab-') {
      var $tabId = $hash.substr(5, $hash.length - 5);
      var $tab = $panel.closest('.js-tabs').find('#' + $tabId + ' a');
      $tab.trigger('click');

      var scrollTop = $(window).scrollTop();

      if ($(window).width() > 700) {
        scrollTop -= 90; // десктопное меню
      } else {
        scrollTop -= 68; // мобильный хедер
      }
      scrollTop -= 90; // вкладки
      $(window).scrollTop(scrollTop);
    }
  }
});

// alt tabs code
// -------------
// var tabsSwiper = new Swiper ('.js-tabs-swiper .swiper-container', {
//   direction: 'horizontal',
//   loop: true,
//   simulateTouch: false,
//   allowTouchMove: false,
//   followFinger: false
// });
//
// $('.js-tabs-swiper .tabs-nav li a').click(function(e) {
//   e.preventDefault();
//   const $this = $(this);
//   const $panel = $this.attr('href');
//   $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
//   if ($panel == '#tab-0'){
//     tabsSwiper.slideTo(1);
//   }
//   if ($panel == '#tab-1'){
//     tabsSwiper.slideTo(2);
//   }
//   if ($panel == '#tab-2'){
//     tabsSwiper.slideTo(3);
//   }
// });
//
// $('.js-tabs-swiper').each(function() {
//   $(this).find('.tabs-nav li:first a').click();
// });


$(document).on('click', '.js-toggle-complect-tab', function complectTabHandler() {
  var $link = $(this);
  var $parent = $link.parent();
  var $tab = $($link.attr('href'));
  var isActive = $parent.hasClass('is-clicked-once');
  if (isActive) {
    $parent.removeClass('is-active');
    $tab.hide().siblings().show();
  }
  $parent.toggleClass('is-clicked-once');
});

},{"./globals":6}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var $topMenu = $('.js-topmenu');

// Закрепленное меню при скролле

function checkIfTopmenuFloated() {
  var windowTopPosition = _globals.$WINDOW.scrollTop();
  var headerHeight = $('.header').height();

  if (windowTopPosition >= headerHeight) {
    $topMenu.addClass('floated');
    _globals.$BODY.addClass('topmenu-floated');
  } else {
    $topMenu.removeClass('floated');
    _globals.$BODY.removeClass('topmenu-floated');
  }
}
_globals.$WINDOW.on('scroll resize', checkIfTopmenuFloated);

// Выпадающие списки в пункте "каталог"
// -------------------
// меню первого уровня (на клик)
$('.js-catalog-menu').on('click', function clickHandler() {
  $(this).toggleClass('catalog-menu_closed');
  $(this).toggleClass('catalog-menu_opened');
});
// клик вне области меню
$(document).mouseup(function (e) {
  var container = $('.js-catalog-menu');
  if (container.has(e.target).length === 0) {
    $('.js-catalog-menu').addClass('catalog-menu_closed');
    $('.js-catalog-menu').removeClass('catalog-menu_opened');
  }
});

// меню каталога первого (при наведении)
// $('.js-catalog-menu').on('mouseenter', function(){ $(this).addClass('catalog-menu_opened'); });
// $('.js-catalog-menu').on('mouseleave', function(){ $(this).removeClass('catalog-menu_opened'); });

// // меню каталога второго уровня (при наведении)
$('.js-catalog-menu .has-dropdown').on('mouseenter', function mouseenterHandler() {
  $(this).addClass('opened');
});
$('.js-catalog-menu .has-dropdown').on('mouseleave', function mouseenterHandler() {
  $(this).removeClass('opened');
});

// выпадающее меню (при наведении)
setTimeout(function () {
  if ($('html.is-touch-device').length === 0) {
    $('.js-topmenu-items > li.has-dropdown').on('mouseenter', function mouseenterHandler() {
      $(this).addClass('opened');
    });

    $('.js-topmenu-items > li.has-dropdown').on('mouseleave', function mouseleaveHandler(e) {
      if ($(e.relatedTarget).hasClass('catalog-menu--dropdown')) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        $(this).removeClass('opened');
      }
    });
  }
}, 500);

// на touch-экране
$('.js-topmenu-items .has-dropdown a').on('click', function clickHandler(e) {
  if ($(this).closest('.has-dropdown').hasClass('opened')) {
    // по второму клику - переход по ссылке
  } else {
    e.preventDefault();
    e.stopPropagation();
    $(this).closest('.has-dropdown').addClass('opened');
  }
});
$(document).mouseup(function (e) {
  var container1 = $('.js-topmenu-items');
  if (container1.has(e.target).length === 0) {
    $('.js-topmenu-items li.has-dropdown').removeClass('opened');
  }
});

},{"./globals":6}],18:[function(require,module,exports){
'use strict';

$('.js-video').on('click', function videoClickHandler(e) {
  e.preventDefault();
  var $this = $(this);
  var src = $this.attr('href');
  $this.html('<iframe src="' + src + '"\n    class="youtube-iframe"\n    frameborder="0"\n    gesture="media"\n    allow="encrypted-media"\n    allowfullscreen=""></iframe>');
  $this.removeClass('js-video');
  $this.removeClass('play-btn');
});

},{}],19:[function(require,module,exports){
'use strict';

if ($('.js-hoverable-table').length) {

  // Подготовка к выводу подсвечиваемых элементов
  // --------------------------------------------
  var count = $('.js-hoverable-table tr.heading th').length;
  var $i = 0;
  $('.js-hoverable-table tr th, .js-hoverable-table tr td').each(function iterationHandler() {
    $i += 1;
    $(this).addClass('col' + $i);
    if ($i === count) {
      $i = 0;
    }
  });

  var $j = 0;
  $('.js-hoverable-table tr').each(function iterationHandler() {
    var $this = $(this);
    if (($this.hasClass('heading') || $this.hasClass('mob-heading')) === false) {
      $j += 1;
      $this.attr('data-row', $j);
    }
  });

  // Подсветка ячеек в таблице размеров
  // ----------------------------------
  $('.js-hoverable-table td').on('mouseenter', function mouseenterHandler() {
    $('.js-hoverable-table th').removeClass('highlight_0');
    $('.js-hoverable-table td').removeClass('highlight_1');

    if ($(this).hasClass('col1')) {
      $('.js-hoverable-table th.col1').addClass('highlight_0');
      $('.js-hoverable-table td.col1').addClass('highlight_1');
    }
    if ($(this).hasClass('col2')) {
      $('.js-hoverable-table th.col2').addClass('highlight_0');
      $('.js-hoverable-table td.col2').addClass('highlight_1');
    }
    if ($(this).hasClass('col3')) {
      $('.js-hoverable-table th.col3').addClass('highlight_0');
      $('.js-hoverable-table td.col3').addClass('highlight_1');
    }
    if ($(this).hasClass('col4')) {
      $('.js-hoverable-table th.col4').addClass('highlight_0');
      $('.js-hoverable-table td.col4').addClass('highlight_1');
    }
    if ($(this).hasClass('col5')) {
      $('.js-hoverable-table th.col5').addClass('highlight_0');
      $('.js-hoverable-table td.col5').addClass('highlight_1');
    }
    if ($(this).hasClass('col6')) {
      $('.js-hoverable-table th.col6').addClass('highlight_0');
      $('.js-hoverable-table td.col6').addClass('highlight_1');
    }
    if ($(this).hasClass('col7')) {
      $('.js-hoverable-table th.col7').addClass('highlight_0');
      $('.js-hoverable-table td.col6').addClass('highlight_1');
    }
    if ($(this).hasClass('col8')) {
      $('.js-hoverable-table th.col8').addClass('highlight_0');
      $('.js-hoverable-table td.col6').addClass('highlight_1');
    }
  });
}

},{}],20:[function(require,module,exports){
'use strict';

var _globals = require('../modules/globals');

// vitrine
// -------
$('.js-doc-links-counter').each(function iterationHandler() {
  var $this = $(this);
  var ctr = $this.find('.product--document-link').length;
  if (ctr === 1) {
    $('.js-doc-links-counter').addClass('doc-links-1');
  }
  if (ctr === 2) {
    $('.js-doc-links-counter').addClass('doc-links-2');
  }
  if (ctr === 3) {
    $('.js-doc-links-counter').addClass('doc-links-3');
  }
  if (ctr === 4) {
    $('.js-doc-links-counter').addClass('doc-links-4');
  }
  if (ctr === 5) {
    $('.js-doc-links-counter').addClass('doc-links-5');
  }
});

var $asideFilterPanel = $('.js-floatable-filter-panel');
if ($asideFilterPanel.length && $('.textblock-section').length) {

  var asideTop = $asideFilterPanel.offset().top;
  var panelHeight = $asideFilterPanel.height();
  var finishTop = $('.textblock-section').offset().top;
  var topBorder = asideTop - 40 - 89;
  var bottomBorder = finishTop - panelHeight - 40 - 89;
  var isPanelNeedsToFix = false;

  _globals.$WINDOW.on('resize', function () {
    isPanelNeedsToFix = _globals.$WINDOW.height() - 140 > panelHeight;
  });
  _globals.$WINDOW.trigger('resize');
  _globals.$WINDOW.on('scroll', function () {

    if (!isPanelNeedsToFix) return;
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (_globals.$WINDOW.width() > 1024) {
      if (scrollTop > topBorder) {

        $asideFilterPanel.addClass('is-fixed');
        if (scrollTop >= bottomBorder) {
          $asideFilterPanel.fadeOut(150);
        } else {
          $asideFilterPanel.fadeIn(150);
        }
      } else {
        $asideFilterPanel.removeClass('is-fixed');
      }
    }
  });
}

// Перемотка к началу страницы при нажатии на пагинацию
// -------------------------------------
if ($('.catalogue-page--pager').length) {
  $('.catalogue-page--pager .pager li:not(.active) a').on('click', function () {
    setTimeout(function () {
      $(window).scrollTo(0, 200);
    }, 250);
  });
}

},{"../modules/globals":6}],21:[function(require,module,exports){
'use strict';

// code only for mainpage
// ----------------------
if ($('.index-page').length) {

  $(window).on('resize', function () {
    var width = $(window).width();
    var pictWidth = $('.catalogue-item--pict').width();

    if (width >= 1600) {
      pictWidth += 60;
      $('.catalogue-item--green-block').width(pictWidth);
      $('.catalogue-item--blue-block').width(pictWidth);
      $('.catalogue-item--green-block').css({ 'margin-left': '-60px' });
      $('.catalogue-item--blue-block').css({ 'margin-left': '-60px' });
      return;
    }
    if (width >= 1200) {
      pictWidth += 40;
      $('.catalogue-item--green-block').width(pictWidth);
      $('.catalogue-item--blue-block').width(pictWidth);
      $('.catalogue-item--green-block').css({ 'margin-left': '-40px' });
      $('.catalogue-item--blue-block').css({ 'margin-left': '-40px' });
      return;
    }
    if (width > 576) {
      pictWidth += 20;
      $('.catalogue-item--green-block').width(pictWidth);
      $('.catalogue-item--blue-block').width(pictWidth);
      $('.catalogue-item--green-block').css({ 'margin-left': '-20px' });
      $('.catalogue-item--blue-block').css({ 'margin-left': '-20px' });
    } else {
      $('.catalogue-item--green-block').width(width - 30);
      $('.catalogue-item--blue-block').width(width - 30);
      $('.catalogue-item--pict').width(width - 30 - 40);
      // убрать до конца баг не удалось иначе overflow-x
      $('.catalogue-item--green-block').css({ 'margin-left': '-20px' });
      $('.catalogue-item--blue-block').css({ 'margin-left': '-20px' });
      $('.catalogue-item--pict').css('margin-right', '0');
    }
  });
  $(window).trigger('resize');

  $('.catalogue-item--title').each(function editTitle() {
    if ($(this).html() === 'Кузнечно-прессовое оборудование') {
      $(this).html('Кузнечно&minus;прессовое оборудование');
    }
  });
}

},{}]},{},[1]);
